"use client";

import { useSession } from "next-auth/react";
import dynamic from "next/dynamic";
const DiscoverPage = dynamic(() => import("@/components/pages/Discover"), {
  ssr: false,
});
const LandingPage = dynamic(() => import("@/components/pages/Landings/Main"));

const StartPageLogic = () => {
  const { data: session } = useSession();

  if (session && session.provider === "credentials") {
    return <DiscoverPage pathname="" />;
  } else {
    return <LandingPage />;
  }
};

export default StartPageLogic;
